import { gql } from '@apollo/client'

import EInputStatus from 'ui-lib/enums/input-status'
import ISearchCore from 'ui-lib/interfaces/ISelectCore'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

import client from 'graphql-lib/index'
import IProduct from '../../interfaces/IProduct'
import {
  CodeColumn,
  SpeciesColumn,
  VarietyColumn,
  NicknameColumn
} from '../../columns/product'
import { EStorage, GetItem } from 'utils/storage'

enum Initialized {
  No = 'Initialized::No',
  Initializing = 'Initialized::Initializing',
  Yes = 'Initialized::Yes',
}

const QueryData = gql`
  query($facilityId: ID) {
    product(facilityId: $facilityId) {
      id
      code
      species
      variety
      nickname
      enabled
    }
  }
`

const columns = [
  CodeColumn,
  SpeciesColumn,
  VarietyColumn,
  NicknameColumn
]

export default class ProductSearchCore
implements ISearchCore<IProduct, string> {
  private required: boolean;

  private initialized: Initialized = Initialized.No;

  private data: IProduct[] = [];

  public inputType = 'text';

  public constructor (required: boolean) {
    this.required = required
  }

  public getValue = (): IProduct => null;

  public getUIValue = (value: IProduct): string =>
    value
      ? value.code
      : '';

  public getStatus = async (value: IProduct): Promise<IInputStatus> => {
    if (this.required && !value) {
      return {
        status: EInputStatus.Error,
        message: 'Required'
      }
    } else {
      return {
        status: EInputStatus.OK,
        message: ''
      }
    }
  };

  public getValues = async (uiValue: string): Promise<IProduct[]> => {
    if (this.initialized === Initialized.No) {
      this.initialized = Initialized.Initializing
      const [facility] = GetItem('facilities', EStorage.EphemeralStorage) ?? []
      const { data: { product } } =
        await client.query({
          query: QueryData,
          variables: { facilityId: facility?.id ?? null }
        })

      this.data = product.filter((_product: IProduct): boolean => _product.enabled)
      this.initialized = Initialized.Yes
    }

    return this.data.filter(
      (data): boolean => columns.some(
        (column): boolean => column.searchFunc(column, uiValue, data)
      )
    )
  };
}
