// Types
import EEntity from 'map-view/enums/entity'
import EChart from 'map-view/enums/chart'

import ISpace from 'graphql-lib/interfaces/ISpace'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IContainer from 'graphql-lib/interfaces/IContainer'
import IChart, { IChartValue, IChartValues } from 'map-view/interfaces/chart'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'
import IHarvestGroup, { IHarvestWeightSession } from 'graphql-lib/interfaces/IHarvestGroup'
// Utils
import { EStorage, GetItem } from 'utils/storage'

// Libs
import Chroma from 'chroma-js'
import { parseISO } from 'date-fns'
import { ICO2SensorRecord, ISensorForChartUtils, ISensorRecordSensorList } from 'graphql-lib/interfaces/ISensorDevice'
import ICrop from 'graphql-lib/interfaces/ICrop'

export const convertTemperature = (value: Array<[number, number]>) => {
  const customer = GetItem('customer', EStorage.EphemeralStorage)
  const temperatureMetric = customer.temperatureMetric

  return value.map(([key, value])=> {
    let newValue: number = value
    if (temperatureMetric === 'fahrenheit') {
      newValue = (value * 9 / 5) + 32
    }

    return [key, newValue]
  })
}

export const SortAscendingObj = (a: any, b: any): number =>
  parseISO(a.createdOn).getTime() - parseISO(b.createdOn).getTime()

export const SortAscendingArr = (a: any, b: any): number =>
  parseISO(a[0]).getTime() - parseISO(b[0]).getTime()

export const SensorCharts = [
  EChart.AbsoluteHumidity,
  EChart.DLI,
  EChart.Humidity,
  EChart.Temperature,
  EChart.umol,
  EChart.VPD
]

export const WaterSystemCharts = [
  EChart.GreenAutomationWaterSystemEC1,
  EChart.GreenAutomationWaterSystemEC2,
  EChart.GreenAutomationWaterSystemPH1,
  EChart.GreenAutomationWaterSystemPH2,
  EChart.GreenAutomationWaterSystemDissolvedO2,
  EChart.GreenAutomationWaterSystemWaterTemp
]

const ChartField = Object.freeze({
  [EChart.AbsoluteHumidity]: 'absoluteHumidity',
  [EChart.DLI]: 'dli',
  [EChart.Humidity]: 'humidity',
  [EChart.Temperature]: 'temperature',
  [EChart.umol]: 'umol',
  [EChart.VPD]: 'vpd',

  [EChart.CO2]: 'co2',

  [EChart.BudDetectionData]: 'budDetection.data',
  [EChart.BudDetectionDensity]: 'budDetection.density',
  [EChart.CanopyCoverageData]: 'canopyCoverage.data',
  [EChart.CanopyCoverageDelta]: 'canopyCoverage.delta',
  [EChart.CanopyHeightData]: 'canopyHeight.data',
  [EChart.CanopyHeightDelta]: 'canopyHeight.delta',
  [EChart.FlowerCoverageData]: 'flowerCoverage.data',
  [EChart.FlowerCoverageDelta]: 'flowerCoverage.delta',
  [EChart.GerminationRate]: 'germinationRate.count',
  [EChart.GerminationRatePercent]: 'germinationRate.count',
  [EChart.HWC]: null, // N/A, it's a calculated value
  [EChart.YieldPrediction]: 'yieldPredictionAverages.count',
  [EChart.BoardCount]: 'boardCount.count',
  [EChart.TotalWeight]: 'totalWeight.count',

  [EChart.TopCanopyCoverageData]: 'TopCanopyCoverage.data',
  [EChart.TopCanopyCoverageDelta]: 'TopCanopyCoverage.delta',
  [EChart.TopCanopyHeightData]: 'topCanopyHeight.data',
  [EChart.TopCanopyHeightDelta]: 'topCanopyHeight.delta',
  [EChart.TopGerminationRate]: 'topGerminationRate.count',
  [EChart.PotCount]: 'potCount.count',
  [EChart.ReadinessCoverage]: 'readinessCoverage.coverage',

  [EChart.GreenAutomationWaterSystemEC1]: 'ec1',
  [EChart.GreenAutomationWaterSystemEC2]: 'ec2',
  [EChart.GreenAutomationWaterSystemPH1]: 'ph1',
  [EChart.GreenAutomationWaterSystemPH2]: 'ph2',
  [EChart.GreenAutomationWaterSystemDissolvedO2]: 'dissolvedO2',
  [EChart.GreenAutomationWaterSystemWaterTemp]: 'waterTemp'
})

export const ChartOrder = Object.freeze({
  [EChart.AbsoluteHumidity]: 0,
  [EChart.DLI]: 1,
  [EChart.Humidity]: 2,
  [EChart.Temperature]: 3,
  [EChart.umol]: 4,
  [EChart.VPD]: 5,

  [EChart.CO2]: 6,

  [EChart.GreenAutomationWaterSystemEC1]: 7,
  [EChart.GreenAutomationWaterSystemEC2]: 8,
  [EChart.GreenAutomationWaterSystemPH1]: 9,
  [EChart.GreenAutomationWaterSystemPH2]: 10,
  [EChart.GreenAutomationWaterSystemDissolvedO2]: 11,
  [EChart.GreenAutomationWaterSystemWaterTemp]: 12,

  [EChart.BudDetectionData]: 13,
  [EChart.BudDetectionDensity]: 14,
  [EChart.CanopyCoverageData]: 15,
  [EChart.CanopyCoverageDelta]: 16,
  [EChart.FlowerCoverageData]: 17,
  [EChart.FlowerCoverageDelta]: 18,
  [EChart.GerminationRate]: 19,
  [EChart.GerminationRatePercent]: 20,
  [EChart.HWC]: 21,
  [EChart.YieldPrediction]: 27,
  [EChart.TotalWeight]: 28,
  [EChart.BoardCount]: 29,

  [EChart.TopCanopyCoverageData]: 22,
  [EChart.TopCanopyCoverageDelta]: 23,
  [EChart.TopCanopyHeightData]: 24,
  [EChart.TopCanopyHeightDelta]: 25,
  [EChart.TopGerminationRate]: 26,
  [EChart.PotCount]: 27,
  [EChart.ReadinessCoverage]: 28,

  [EChart.CanopyHeightData]: 29,
  [EChart.CanopyHeightDelta]: 30
})

export const ChartName = Object.freeze({
  [EChart.AbsoluteHumidity]: 'Abs. Humidity',
  [EChart.DLI]: 'DLI',
  [EChart.Humidity]: 'Humidity',
  [EChart.Temperature]: 'Temperature',
  [EChart.umol]: 'μmol',
  [EChart.VPD]: 'VPD',

  [EChart.CO2]: 'CO2',

  [EChart.BudDetectionData]: 'Flower Count',
  [EChart.BudDetectionDensity]: 'Flowers / Sq Meter',
  [EChart.CanopyCoverageData]: 'Canopy Index',
  [EChart.CanopyCoverageDelta]: 'Canopy Daily Growth',
  [EChart.CanopyHeightData]: 'Canopy Height',
  [EChart.CanopyHeightDelta]: 'Canopy Height Growth',
  [EChart.FlowerCoverageData]: 'Flower Index',
  [EChart.FlowerCoverageDelta]: 'Flower Daily Growth',
  [EChart.GerminationRate]: 'Germination Count',
  [EChart.GerminationRatePercent]: 'Germination Rate',
  [EChart.HWC]: 'Harvest Weight',
  [EChart.YieldPrediction]: 'Board Forecast',
  [EChart.BoardCount]: 'Board Count',
  [EChart.TotalWeight]: 'Weight Forecast',
  [EChart.TopCanopyCoverageData]: 'Top Canopy Index',
  [EChart.TopCanopyCoverageDelta]: 'Top Canopy Daily Growth',
  [EChart.TopCanopyHeightData]: 'Top Canopy Height',
  [EChart.TopCanopyHeightDelta]: 'Top Canopy Height Growth',
  [EChart.TopGerminationRate]: 'Top Germination Count',
  [EChart.PotCount]: 'Pot Count',
  [EChart.ReadinessCoverage]: 'Readiness Index',

  [EChart.GreenAutomationWaterSystemEC1]: 'EC1',
  [EChart.GreenAutomationWaterSystemEC2]: 'EC2',
  [EChart.GreenAutomationWaterSystemPH1]: 'pH1',
  [EChart.GreenAutomationWaterSystemPH2]: 'pH2',
  [EChart.GreenAutomationWaterSystemDissolvedO2]: 'Dissolved O2',
  [EChart.GreenAutomationWaterSystemWaterTemp]: 'Water Temperature'
})

export const ChartAccuracy = Object.freeze({
  [EChart.AbsoluteHumidity]: 2,
  [EChart.DLI]: 2,
  [EChart.Humidity]: 2,
  [EChart.Temperature]: 2,
  [EChart.umol]: 0,
  [EChart.VPD]: 2,

  [EChart.CO2]: 2,

  [EChart.BudDetectionData]: 0,
  [EChart.BudDetectionDensity]: 2,
  [EChart.CanopyCoverageData]: 2,
  [EChart.CanopyCoverageDelta]: 2,
  [EChart.CanopyHeightData]: 2,
  [EChart.CanopyHeightDelta]: 2,
  [EChart.FlowerCoverageData]: 2,
  [EChart.FlowerCoverageDelta]: 2,
  [EChart.GerminationRate]: 0,
  [EChart.GerminationRatePercent]: 0,
  [EChart.HWC]: 2,
  [EChart.YieldPrediction]: 0,
  [EChart.BoardCount]: 0,
  [EChart.TotalWeight]: 0,

  [EChart.TopCanopyCoverageData]: 2,
  [EChart.TopCanopyCoverageDelta]: 2,
  [EChart.TopCanopyHeightData]: 2, // To confirm
  [EChart.TopCanopyHeightDelta]: 2, // To confirm
  [EChart.TopGerminationRate]: 0,
  [EChart.PotCount]: 0,
  [EChart.ReadinessCoverage]: 2,

  [EChart.GreenAutomationWaterSystemEC1]: 2,
  [EChart.GreenAutomationWaterSystemEC2]: 2,
  [EChart.GreenAutomationWaterSystemPH1]: 1,
  [EChart.GreenAutomationWaterSystemPH2]: 1,
  [EChart.GreenAutomationWaterSystemDissolvedO2]: 2,
  [EChart.GreenAutomationWaterSystemWaterTemp]: 2
})

export const ChartUnit = Object.freeze({
  [EChart.AbsoluteHumidity]: 'g/m³',
  [EChart.DLI]: 'mol',
  [EChart.Humidity]: '%',
  get [EChart.Temperature] () {
    const customer = GetItem('customer', EStorage.EphemeralStorage)
    const temperatureMetric = customer.temperatureMetric
    const temperatureUnit = temperatureMetric === 'celcius'
      ? '°C'
      : '°F'

    return temperatureUnit
  },
  [EChart.umol]: 'μmol',
  [EChart.VPD]: 'hPa',

  [EChart.CO2]: 'ppm',

  [EChart.BudDetectionData]: '',
  [EChart.BudDetectionDensity]: ' / m²',
  [EChart.CanopyCoverageData]: '%',
  [EChart.CanopyCoverageDelta]: '%',
  [EChart.CanopyHeightData]: 'cm',
  [EChart.CanopyHeightDelta]: 'cm',
  [EChart.FlowerCoverageData]: '%',
  [EChart.FlowerCoverageDelta]: '%',
  [EChart.GerminationRate]: '',
  [EChart.GerminationRatePercent]: '%',
  [EChart.HWC]: '', // N/A, will be dynamically provided
  [EChart.BoardCount]: '',

  [EChart.TopCanopyCoverageData]: '%',
  [EChart.TopCanopyCoverageDelta]: '%',
  [EChart.TopCanopyHeightData]: '%', // To confirm
  [EChart.TopCanopyHeightDelta]: '%', // To confirm
  [EChart.TopGerminationRate]: '',
  [EChart.PotCount]: '',
  [EChart.ReadinessCoverage]: '%',

  [EChart.GreenAutomationWaterSystemEC1]: 'mS/cm',
  [EChart.GreenAutomationWaterSystemEC2]: 'mS/cm',
  [EChart.GreenAutomationWaterSystemPH1]: '',
  [EChart.GreenAutomationWaterSystemPH2]: '',
  [EChart.GreenAutomationWaterSystemDissolvedO2]: 'mg/L',
  get [EChart.GreenAutomationWaterSystemWaterTemp] () {
    const customer = GetItem('customer', EStorage.EphemeralStorage)
    const temperatureMetric = customer.temperatureMetric
    const temperatureUnit = temperatureMetric === 'celcius'
      ? '°C'
      : '°F'

    return temperatureUnit
  },
  get [EChart.YieldPrediction] () {
    const customer = GetItem('customer', EStorage.EphemeralStorage);
    // weight_unit= 0:gram, 1:kilogram, 2:pound, 3:ounce at app/models/organization.rb
    const isMetric = ['0', '1'].includes(customer.weightUnit);
    // If imperial unit weight should be ounces, if metric should be grams.
    return isMetric ? 'g' : 'oz'
  },
  get [EChart.TotalWeight] () {
    const customer = GetItem('customer', EStorage.EphemeralStorage)
    // weight_unit= 0:gram, 1:kilogram, 2:pound, 3:ounce at app/models/organization.rb
    const isMetric = ['0', '1'].includes(customer.weightUnit);
    // If imperial total weight should be lbs, if metric should be kg.
    return isMetric ? 'kg' : 'lbs';
  }
})

export const Chartable = Object.freeze({
  [EChart.AbsoluteHumidity]: true,
  [EChart.DLI]: true,
  [EChart.Humidity]: true,
  [EChart.Temperature]: true,
  [EChart.umol]: true,
  [EChart.VPD]: true,

  [EChart.CO2]: true,

  [EChart.BudDetectionData]: true,
  [EChart.BudDetectionDensity]: true,
  [EChart.CanopyCoverageData]: true,
  [EChart.CanopyCoverageDelta]: true,
  [EChart.CanopyHeightData]: true,
  [EChart.CanopyHeightDelta]: true,
  [EChart.FlowerCoverageData]: true,
  [EChart.FlowerCoverageDelta]: false,
  [EChart.GerminationRate]: false,
  [EChart.GerminationRatePercent]: false,
  [EChart.HWC]: true,
  [EChart.YieldPrediction]: false,
  [EChart.BoardCount]: false,
  [EChart.TotalWeight]: false,

  [EChart.TopCanopyCoverageData]: true,
  [EChart.TopCanopyCoverageDelta]: true,
  [EChart.TopCanopyHeightData]: true,
  [EChart.TopCanopyHeightDelta]: true,
  [EChart.TopGerminationRate]: false,
  [EChart.PotCount]: false,
  [EChart.ReadinessCoverage]: false,

  [EChart.GreenAutomationWaterSystemEC1]: true,
  [EChart.GreenAutomationWaterSystemEC2]: true,
  [EChart.GreenAutomationWaterSystemPH1]: true,
  [EChart.GreenAutomationWaterSystemPH2]: true,
  [EChart.GreenAutomationWaterSystemDissolvedO2]: true,
  [EChart.GreenAutomationWaterSystemWaterTemp]: true
})

export const ChartType = Object.freeze({
  [EChart.AbsoluteHumidity]: 'line',
  [EChart.DLI]: 'line',
  [EChart.Humidity]: 'line',
  [EChart.Temperature]: 'line',
  [EChart.umol]: 'line',
  [EChart.VPD]: 'line',

  [EChart.CO2]: 'line',

  [EChart.BudDetectionData]: 'line',
  [EChart.BudDetectionDensity]: 'line',
  [EChart.CanopyCoverageData]: 'line',
  [EChart.CanopyCoverageDelta]: 'column',
  [EChart.TopCanopyHeightData]: 'line',
  [EChart.TopCanopyHeightDelta]: 'column',
  [EChart.CanopyHeightData]: 'line',
  [EChart.CanopyHeightDelta]: 'column',
  [EChart.FlowerCoverageData]: 'line',
  [EChart.FlowerCoverageDelta]: 'column',
  [EChart.GerminationRate]: 'column',
  [EChart.GerminationRatePercent]: 'column',
  [EChart.HWC]: 'column',
  [EChart.YieldPrediction]: 'column',
  [EChart.BoardCount]: 'column',
  [EChart.TotalWeight]: 'column',

  [EChart.TopCanopyCoverageData]: 'line',
  [EChart.TopCanopyCoverageDelta]: 'column',
  [EChart.TopGerminationRate]: 'column',
  [EChart.PotCount]: 'column',
  [EChart.ReadinessCoverage]: 'column',

  [EChart.GreenAutomationWaterSystemEC1]: 'line',
  [EChart.GreenAutomationWaterSystemEC2]: 'line',
  [EChart.GreenAutomationWaterSystemPH1]: 'line',
  [EChart.GreenAutomationWaterSystemPH2]: 'line',
  [EChart.GreenAutomationWaterSystemDissolvedO2]: 'line',
  [EChart.GreenAutomationWaterSystemWaterTemp]: 'line'
})

export const ChartColor = Object.freeze({
  [EChart.AbsoluteHumidity]: '#F44336',
  [EChart.DLI]: '#E91E63',
  [EChart.Humidity]: '#9C27B0',
  [EChart.Temperature]: '#673AB7',
  [EChart.umol]: '#3F51B5',
  [EChart.VPD]: '#2196F3',

  [EChart.CO2]: '#03A9F4',

  [EChart.BudDetectionData]: '#00BCD4',
  [EChart.BudDetectionDensity]: '#0097A7',
  [EChart.CanopyCoverageData]: '#4DB6AC',
  [EChart.CanopyCoverageDelta]: '#00796B',
  [EChart.CanopyHeightData]: '#4DB6AC',
  [EChart.CanopyHeightDelta]: '#00796B',
  [EChart.FlowerCoverageData]: '#81C784',
  [EChart.FlowerCoverageDelta]: '#388E3C',
  [EChart.GerminationRate]: '#FFEB3B',
  [EChart.GerminationRatePercent]: '#FFC107',
  [EChart.HWC]: '#0718FF',
  [EChart.YieldPrediction]: '#AACC33',
  [EChart.BoardCount]: '#AACC33',
  [EChart.TotalWeight]: '#AACC33',

  [EChart.TopCanopyCoverageData]: '#4DB6AC',
  [EChart.TopCanopyCoverageDelta]: '#00796B',
  [EChart.TopCanopyHeightData]: '#4DB6AC',
  [EChart.TopCanopyHeightDelta]: '#4DB6AC',
  [EChart.TopGerminationRate]: '#FFEB3B',
  [EChart.PotCount]: '#00796B',
  [EChart.ReadinessCoverage]: '#FFEB3B',

  [EChart.GreenAutomationWaterSystemEC1]: '#FD9B43',
  [EChart.GreenAutomationWaterSystemEC2]: '#FCE942',
  [EChart.GreenAutomationWaterSystemPH1]: '#FF889D',
  [EChart.GreenAutomationWaterSystemPH2]: '#CEC4F8',
  [EChart.GreenAutomationWaterSystemDissolvedO2]: '#030089',
  [EChart.GreenAutomationWaterSystemWaterTemp]: '#892100'
})

const RandomChartColor = (chart: EChart): string => {
  const darken = Math.random() < 0.5
  const desaturate = Math.random() < 0.5

  const lightness = Math.random()
  const saturation = Math.random()

  const color = ChartColor[chart]
  let randomColor = Chroma(color)

  if (darken) {
    randomColor = randomColor.darken(lightness)
  } else {
    randomColor = randomColor.brighten(lightness)
  }

  if (desaturate) {
    randomColor = randomColor.desaturate(saturation)
  } else {
    randomColor = randomColor.saturate(saturation)
  }

  return randomColor.hex()
}

const sanitizeSensorData: (chart: EChart) => (datum: IChartValue | Array<number>) => boolean = (chart) => {
  switch (chart) {
    case EChart.GreenAutomationWaterSystemWaterTemp:
      // inactive sensors from Green Automation return 0 for water temperature
      return ([_time, value]) => value !== 0
    case EChart.GreenAutomationWaterSystemPH1:
      // inactive sensors from Green Automation return 0 for pH
      return ([_time, value]) => value !== 0
    case EChart.GreenAutomationWaterSystemPH2:
      // inactive sensors from Green Automation return 0 for pH
      return ([_time, value]) => value !== 0
    default:
      return ([_time, value]) => value != null
  }
}

type IParentChartOptions = (ISpace | IInventory | IContainer | ICrop | ISensorForChartUtils)
export type IParentNewChart = IParentChartOptions & Record<keyof IParentChartOptions, any>;
export const NewChart = (
  parent: IParentNewChart,
  chart: EChart
): IChart => {
  if (
    parent.__typename !== 'Space' &&
    (
      SensorCharts.includes(chart) ||
      chart === EChart.CO2 ||
      WaterSystemCharts.includes(chart)
    )
  ) {
    throw new Error(`Cannot make a ${chart} Chart on a ${parent.__typename}`)
  }

  if (
    (
      parent.__typename !== 'Inventory' &&
      parent.__typename !== 'Container'
    ) &&
    (
      chart === EChart.BudDetectionData ||
      chart === EChart.BudDetectionDensity ||
      chart === EChart.CanopyCoverageData ||
      chart === EChart.CanopyCoverageDelta ||
      chart === EChart.CanopyHeightData ||
      chart === EChart.CanopyHeightDelta ||
      chart === EChart.FlowerCoverageData ||
      chart === EChart.FlowerCoverageDelta ||
      chart === EChart.GerminationRate ||
      chart === EChart.HWC ||
      chart === EChart.TopCanopyCoverageData ||
      chart === EChart.TopCanopyCoverageDelta ||
      chart === EChart.TopGerminationRate ||
      chart === EChart.PotCount ||
      chart === EChart.ReadinessCoverage ||
      chart === EChart.YieldPrediction ||
      chart === EChart.BoardCount ||
      chart === EChart.TotalWeight
    )
  ) {
    throw new Error(`Cannot make a ${chart} Chart on a ${parent.__typename}`)
  }

  if ('sensor' in parent && SensorCharts.includes(chart)) {
    if (!parent.sensor ||
      !parent.sensor.find((sensor: ISensorRecordSensorList) => sensor?.sensorRecord[0] && 'co2' in sensor?.sensorRecord[0])
    ) {
      return
    }

    const field = ChartField[chart]
    let value = parent.sensor
      .find(sensor => !sensor.sensorRecord[0].hasOwnProperty('co2')).sensorRecord
      .sort(SortAscendingObj)
      .map(sensorRecord => [parseISO(sensorRecord.createdOn).getTime(), sensorRecord[field]])
      // Don't track entries with null values
      .filter((datum: Array<[number, number | null]>) => datum[1] != null)

    if (chart === EChart.Temperature) {
      value = convertTemperature(value)
    }

    return {
      parentType: EEntity.Space,
      parentID: parent.id,
      chart,
      value,

      color: RandomChartColor(chart),
      meta: {
        parent,
        parentName: parent.name
      }
    }
  }

  if ('sensor' in parent && chart === EChart.CO2) {
    if (
      !parent.sensor ||
      !parent.sensor
        .find((sensor: ISensorRecordSensorList) => 'co2' in sensor.sensorRecord[0])
    ) {
      return
    }

    const field = ChartField[chart]
    const value = parent.sensor
      .find((sensor: ISensorRecordSensorList) => 'co2' in sensor.sensorRecord[0]).sensorRecord
      .sort(SortAscendingObj)
      .map((sensorRecord: any) => [parseISO(sensorRecord.createdOn).getTime(), sensorRecord[field]])

    return {
      parentType: EEntity.Space,
      parentID: parent.id,

      chart,
      value,

      color: RandomChartColor(chart),
      meta: {
        parent,
        parentName: parent.name
      }
    }
  }

  // Data/Delta
  if (
    chart === EChart.BudDetectionData ||
    chart === EChart.BudDetectionDensity ||
    chart === EChart.CanopyCoverageData ||
    chart === EChart.CanopyCoverageDelta ||
    chart === EChart.CanopyHeightData ||
    chart === EChart.CanopyHeightDelta ||
    chart === EChart.FlowerCoverageData ||
    chart === EChart.FlowerCoverageDelta ||
    chart === EChart.TopCanopyCoverageData ||
    chart === EChart.TopCanopyCoverageDelta
  ) {
    const chartFieldParts = ChartField[chart].split('.');
    const parentField = chartFieldParts[0] as keyof IParentChartOptions;
    const dataField = chartFieldParts[1];
    if (
      !parent[parentField]?.[0][dataField] ||
      parent[parentField]?.[0][dataField].length === 0
    ) {
      return
    }

    let parentName
    const parentType = parent.__typename === 'Inventory' ? EEntity.Inventory : EEntity.Container
    if ('cropName' in parent && parentType === EEntity.Inventory) {
      parentName = parent.cropName
    }

    if ('inventory' in parent && parentType === EEntity.Container) {
      parentName = `${parent?.inventory[0]?.cropName} - ${parent?.inventory[0]?.code}`
    }

    if ('code' in parent && parent.code) {
      parentName = `${parentName} - ${parent.code}`;
    }

    const value = parent[parentField][0][dataField]
      .sort(SortAscendingArr)
      .map((entry: [date: string, value: string]) => {
        const [date, value] = entry;
        return [parseISO(date).getTime() as number, value];
      })

    return {
      parentType,
      parentID: parent.id,

      chart,
      value,

      color: RandomChartColor(chart),
      meta: { parent, parentName }
    }
  }

  // Count
  if (
    chart === EChart.GerminationRate ||
    chart === EChart.TopGerminationRate ||
    chart === EChart.PotCount ||
    chart === EChart.ReadinessCoverage ||
    chart === EChart.YieldPrediction ||
    chart === EChart.BoardCount ||
    chart === EChart.TotalWeight
  ) {
    const chartFieldSplit = ChartField[chart].split('.')
    const parentField = chartFieldSplit[0] as keyof IParentChartOptions;
    const dataField = chartFieldSplit[1];
    if (!parent[parentField]?.[0][dataField]) {
      return
    }

    let parentName
    const parentType = parent.__typename === 'Inventory' ? EEntity.Inventory : EEntity.Container
    if ('cropName' in parent && parentType === EEntity.Inventory) {
      parentName = `${parent.cropName} - ${parent.code}`
    }

    if ('inventory' in parent && parentType === EEntity.Container) {
      parentName = `${parent?.inventory[0]?.cropName}  -  ${parent?.inventory[0]?.code} - ${parent.code}`
    }

    const value = parent[parentField][0][dataField]

    return {
      parentType,
      parentID: parent.id,

      chart,
      value,

      color: RandomChartColor(chart),
      meta: { parent, parentName }
    }
  }

  if (chart === EChart.GerminationRatePercent && 'productSeedsPerContainer' in parent) {
    if (
      !parent.cropContainersPerLocator ||
      !parent.productSeedsPerContainer ||
      !parent.germinationRate ||
      !parent.germinationRate?.[0].count
    ) {
      return
    }

    const parentType = parent.__typename === 'Inventory'
      ? EEntity.Inventory
      : EEntity.Container

    let parentName;
    if ('cropName' in parent && parentType === EEntity.Inventory) {
      parentName = parent.cropName + ' - ' + parent.code;
    }

    if ('inventory' in parent) {
      const parentValue = parent as ICrop
      parentName = parentValue.inventory[0]?.cropName + ' - ' + parentValue.inventory[0]?.code + ' - ' + parentValue.code;
    }

    const seedsPerLocator = parent.productSeedsPerContainer * parent.cropContainersPerLocator
    const value = (parent.germinationRate[0].count / seedsPerLocator) * 100

    return {
      parentType,
      parentID: parent.id,

      chart,
      value,

      color: RandomChartColor(chart),
      meta: { parent, parentName }
    }
  }

  if (chart === EChart.HWC) {
    // Right now it's always container
    const parentType = parent.__typename === 'Inventory'
      ? EEntity.Inventory
      : EEntity.Container

    let parentName
    if ('cropName' in parent && parentType === EEntity.Inventory) {
      parentName = parent.cropName + ' - ' + parent.code;
    }
    if ('inventory' in parent && parentType === EEntity.Container) {
      parentName = parent?.inventory[0]?.cropName + ' - ' + parent?.inventory[0]?.code + ' - ' + parent.code;
    }

    // Looping through all harvest groups, and then looping
    // through each HarvestGroup.harvestWeightSession
    // and returning the latest weight/unit from the most
    // recent harvestWeightSession
    if ('harvestGroups' in parent) {
      const { totalWeight, weightUnit, start } = parent.harvestGroups?.reduce(
        (latest: IHarvestWeightSession, hg: IHarvestGroup) => {
          const { totals } = hg
          const recentTotal = totals.reduce(
            (retVal, t) => {
              return retVal?.start > t.start ? retVal : t
            },
            null
          )
          return latest === null
            ? recentTotal
            : recentTotal?.start > latest?.start
              ? recentTotal
              : latest
        },
        null
      ) ?? {}
      if (totalWeight == null) return // Nothing to chart
      return {
        parentType,
        parentID: parent.id,
        chart,
        value: [[parseISO(String(start)).getTime(), totalWeight]],
        color: RandomChartColor(chart),
        meta: { parent, parentName },
        dynamicUnit: weightUnit
      }
    }
  }

  if ('waterSystemRecords' in parent &&
    WaterSystemCharts.includes(chart)
  ) {
    if (!parent.waterSystemRecords) { return }

    const field: 'waterSystem' | 'ec1' | 'ec2' | 'ph1' | 'ph2' | 'dissolvedO2' | 'waterTemp' = ChartField[chart]

    let value = parent.waterSystemRecords
      .sort(SortAscendingObj)
      .map((waterSystemRecord) =>
        [parseISO(waterSystemRecord.createdOn).getTime(), waterSystemRecord[field]])
      .filter(sanitizeSensorData(chart))

    if (value.length === 0) return
    let assignedTypeOnValue: Array<[number, number]>;
    if (chart === EChart.GreenAutomationWaterSystemWaterTemp) {
      assignedTypeOnValue = value as Array<[number, number]>
      value = convertTemperature(assignedTypeOnValue)
    }

    assignedTypeOnValue = value as Array<[number, number]>
    return {
      parentType: EEntity.Space,
      parentID: parent.id,

      chart,
      value: assignedTypeOnValue,

      color: RandomChartColor(chart),
      meta: { parent, parentName: parent.name }
    }
  }
}
