/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

import EInputStatus from 'ui-lib/enums/input-status'
import ISearchCore from 'ui-lib/interfaces/ISelectCore'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

import { EStorage, GetItem } from 'utils/storage'
import client from 'graphql-lib/index'
import IFlag from '../../interfaces/IFlag'
import {
  TitleColumn,
  DescriptionColumn
} from '../../columns/flag'

// Enums
import EIssueType from 'graphql-lib/enums/issue-type'

enum Initialized {
  No = 'Initialized::No',
  Initializing = 'Initialized::Initializing',
  Yes = 'Initialized::Yes',
}

const QueryFlag = gql`
  query {
    flag {
      id
      icon
      color
      title
      description
    }
  }
`

const columns = [
  TitleColumn,
  DescriptionColumn
]

export default class FlagSearchCore
implements ISearchCore<IFlag, string> {
  public inputType = 'text';
  private initialized: Initialized = Initialized.No;
  private data: IFlag[] = [];

  private required: boolean;
  readonly isIssueCore: boolean = false;

  public constructor (required: boolean, isIssueCore: boolean = false) {
    this.required = required;
    this.isIssueCore = isIssueCore;
  }

  public getValue = (): IFlag => null;

  public getUIValue = (value: IFlag): string =>
    value
      ? value.title
      : '';

  public getStatus = async (value: IFlag): Promise<IInputStatus> => {
    if (this.required && !value) {
      return {
        status: EInputStatus.Error,
        message: 'Required'
      }
    } else {
      return {
        status: EInputStatus.OK,
        message: ''
      }
    }
  };

  private sortFlags = (firstFlag: IFlag, secondFlag: IFlag) => {
    // Return -1 if the first flag comes before the second flag alphabetically.
    return firstFlag.title < secondFlag.title ? -1 : 1
  }

  public getValues = async (uiValue: string): Promise<IFlag[]> => {
    if (this.initialized === Initialized.No) {
      this.initialized = Initialized.Initializing
      const { data: { flag: flags } } = await client.query({ query: QueryFlag });

      // Only show users either issues or flags in dropdown
      if (this.isIssueCore) {
        this.data = flags
          .filter((flag: IFlag) => Object.values(EIssueType).includes(flag.title as EIssueType))
          .map((flag: IFlag) => {
            // All issue task icons show up on the map as our 'ra' logo for issues. This updates it in the dropdown as well
            return { ...flag, icon: 'ra', color: 'solid-#d13b3b' }
          })
      } else {
        this.data = flags
      }

      this.initialized = Initialized.Yes
    }
    return this.data.filter(
      (data): boolean => columns.some(
        (column): boolean => column.searchFunc(column, uiValue, data)
      )
    ).sort(this.sortFlags)
  };
}
