/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// GraphQL
import { gql } from '@apollo/client'
import client from 'graphql-lib'
import useStorage from 'react-lib/use-storage'
import { EStorage, GetItem } from 'utils/storage'

// Queries
const QueryProduct = gql`
  query($code: String, $facilityId: ID) {
    product(code: $code, facilityId: $facilityId) {
      id
    }
  }
`

const ProductCode = async (val: string): Promise<IInputStatus> => {
  if (/^$/.test(val)) {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  }
  const [facility] = useStorage('facilities', EStorage.EphemeralStorage)
  const {
    error,
    data
  } = await client.query({
    query: QueryProduct,
    variables: {
      code: val,
      facilityId: facility?.[0]?.id ?? null
    }
  })

  if (
    error ||
    data.product.length === 0
  ) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

const RequiredProductCode = async (val: string): Promise<IInputStatus> => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  }
  const [facility] = GetItem('facilities', EStorage.EphemeralStorage) ?? []
  const {
    error,
    data
  } = await client.query({
    query: QueryProduct,
    variables: {
      code: val,
      facilityId: facility?.id ?? null
    }
  })

  if (
    error ||
    data.product.length === 0
  ) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

export {
  ProductCode,
  RequiredProductCode
}
